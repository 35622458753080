<!--
 * @Author: wxb
 * @Date: 2021-06-22 17:41:59
 * @LastEditTime: 2021-08-03 13:41:29
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Project\noticeDetail.vue
-->
<template>
  <div class="containner">
    <headerNav :activeIdx='3'></headerNav>
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/project'}">找项目</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/finishNotice'}">精编公告</el-breadcrumb-item>
        <el-breadcrumb-item>公告详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="notice"
           v-show="canSee">
        <div class="notice-panel">
          <div class="notice-title">{{noticeDetail.noticeTitle}}</div>
          <div class="notice-panel-info">
            <div class="notice-publish">{{noticeDetail.publishDate}}</div>
            <div class="notice-source">{{noticeDetail.source}}</div>
            <div class="notice-orgin-link">{{noticeDetail.noticeTypeName}}</div>
            <div class="notice-follow"
                 :class="{followed:noticeDetail.action==='add' }"
                 title="关注公告实时跟进关联项目最新公告"
                 @click="handleFollow">
              <svg class="icon"
                   aria-hidden="true">
                <use :xlink:href="noticeDetail.action | formatIcon"></use>
              </svg>关注公告
            </div>
          </div>
        </div>
        <div class="notice-info">
          <iframe :src="noticeLink"
                  height="550"
                  width="1200"
                  v-show="!noticeDetail.content"></iframe>
          <div class="notice-panel-content"
               v-html="noticeDetail.content"
               v-show="noticeDetail.content"></div>
          <div class="notice-visit">
            <el-button type="info"
                       @click="handleVisitOrgin">点击查看原文<i class="el-icon-d-arrow-right"></i></el-button>
          </div>
        </div>

        <div class="panel"
             v-show="timelineData.length>1">
          <div class="panel-title">项目进度</div>
          <div class="panel-content">
            <el-timeline>
              <el-timeline-item :timestamp="item.releaseDate"
                                placement="top"
                                v-for="(item,index) in timelineData"
                                :key="index">
                <el-card>
                  <div class="project-schedule-item-title">
                    <a @click="handleClickNotice(item.id)">{{item.title}}</a>
                  </div>
                  <el-tag>{{item.noticeTypeName}}</el-tag>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
      <viporlogin bgStr="5"
                  :type="viporlogin"
                  v-show="!canSee"></viporlogin>
    </div>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import viporlogin from '@/components/viporlogin'
import { mapGetters } from 'vuex'
import { getProjectNoticeDetails } from '@/api/project'
import { follow } from '@/api/follow'
export default {
  name: 'noticeDetail',
  components: {
    headerNav,
    footerNav,
    viporlogin
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  },
  data() {
    return {
      viporlogin: 'login',
      canSee: true,
      id: '',
      noticeLink: '',
      timelineData: [],
      noticeDetail: {}
    }
  },
  methods: {
    handleVisitOrgin() {
      window.open(this.noticeDetail.originalLink, '_blank')
    },
    authCheck() {
      const authObj = authenticate('finishNoticeDetail', this.memberType)
      if (authObj.flag) {
        // 初始化页面
        this.initPage()
        this.canSee = true
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    },
    initPage() {
      const params = {
        id: this.id
      }
      getProjectNoticeDetails(params).then(res => {
        if (res.ok) {
          const detail = res.content || ''
          const content = detail.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&').replace(/&quot;/g, '"').replace(/&apos;/g, '\'')
          this.noticeDetail = {
            id: res.id,
            noticeTitle: res.noticeTitle,
            publishDate: res.publishDate,
            source: res.source,
            noticeTypeCode: res.noticeTypeCode,
            noticeTypeName: res.noticeTypeName,
            originalLink: res.originalLink,
            content,
            action: res.action,
            projectId: res.projectId,
            projectName: res.projectName
          }
          this.noticeLink = '/originUrl?link=' + res.originalLink
          this.timelineData = res.lst
        }
      }).catch(err => {
        console.log(err)
      })
    },
    handleClickNotice(id) {
      if (id !== this.id) {
        this.$router.replace({
          params: {
            id
          }
        })
        this.id = id
        this.initPage()
        document.body.scrollIntoView()
      }
    },
    handleFollow() {
      const params = {
        concernsId: this.id,
        concernsType: 'notice',
        concernsTitle: this.noticeDetail.noticeTitle
      }
      if (this.noticeDetail.action === 'cancle') {
        params.action = 'add'
        params.relationProjectId = this.noticeDetail.projectId
        params.relationProjectName = this.noticeDetail.projectName
        this.handleFollowEvent(params)
      } else if (this.noticeDetail.action === 'add') {
        params.action = 'cancle'
        this.$confirm('确认取消公告关注?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.handleFollowEvent(params)
        })
      }
    },
    handleFollowEvent(params) {
      follow(params).then(res => {
        if (res.ok) {
          this.noticeDetail.action = params.action
          if (params.action === 'add') {
            this.$message({
              message: '关注成功',
              type: 'success'
            })
          } else {
            this.$message({
              message: '取消关注成功',
              type: 'success'
            })
          }
        } else {
          this.$message({
            message: res,
            type: 'warning'
          })
        }
      }).catch(err => { console.log(err) })
    }
  },
  mounted() {
    const id = this.$route.params.id
    this.id = id
    if (getToken()) {
      if (this.memberType === 'tourist') {
        this.$store.dispatch('getMemberInfo').then(res => {
          this.authCheck()
        })
      } else {
        this.authCheck()
      }
    } else {
      this.authCheck()
    }
  },
  filters: {
    formatIcon(action) {
      if (action === 'add') {
        return '#icon-shoucang2'
      } else {
        return '#icon-shoucang1'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.el-breadcrumb {
  height: 5.4rem;
  line-height: 5.4rem;
}

.notice {
  &-panel {
    border: 1px solid #eee;
    padding: 15px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    &-info {
      display: flex;
      width: 860px;
      margin: 10px auto;
      justify-content: space-around;
      font-size: 14px;

      .notice-follow {
        cursor: pointer;

        &.followed {
          .icon {
            color: #ec2929;
          }
        }
      }
    }

    &-content {
      width: 1200px;
      height: 550px;
      overflow: hidden;
      overflow-y: scroll;
      margin: 0 auto;
      text-align: left;
      line-height: 20px;
      span {
        display: inline-block;
      }
      p {
        line-height: 1rem;
      }
    }
  }

  &-title {
    font-size: 16px;
    text-align: center;
  }

  &-content-title {
    background: #f4f4ff;
  }
}

.notice {
  &-info {
    text-align: center;
    height: 55rem;
    position: relative;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
  }

  &-visit {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5.5rem;
    padding: 0.7rem;
    background: rgba(222, 222, 222, 0.6);
  }
}

.panel {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 1rem;
  &-title {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 16px;
    font-weight: 500;
  }

  &-content {
    padding: 2rem 0;
  }
}

.project-schedule-item-title {
  padding: 0.5rem 0;
  a {
    cursor: pointer;
    &:hover {
      color: #398ce2;
    }
  }
}
</style>
